body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}
html, body, div.pageholder, div.navigation, .project-language {
  background: #111;
}
.header-subtext {
  color: #bbb;
}
.pageholder {
  width: 100%;
}
.page {
  display: table;
  margin-bottom: 50px;
  width: 100%;
}
div.content.pageholder {
  padding: 5%;
}
.box {
  box-shadow: 0 0 10px #000;
  background: #212121;
}
.page::after {
  content: "";
  width: 100%;
  height: 1px;
  z-index: 1;
  left: 0;
  display: inline-block;
}
.footer {
  text-align: center;
  font-size: 11pt;
  padding: 10px 0;
}
a, a:hover, span.date {
  color: #e46b4a;
}
.home.pageholder::after,
.page::after,
div.line {
  background-color: #e46b4a;
}
.boxed {
  border: 2px solid #e46b4a;
}
.project-link-icon path {
  fill: #fff;
}
@keyframes color {
  from {
    fill: #ffffff;
    color: #ffffff;
  }
  to {
    fill: #e38871;
    color: #e38871;
  }
}
@keyframes grow {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}