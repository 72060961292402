div.me {
  background: url(/pic.jpg) no-repeat center;
  background-size: cover;
  width: 200px;
  height: 200px;
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0 0 10px 3px #000;
  filter: opacity(0.85);
}
div.me-holder {
  display: inline-block;
  width: 40%;
  text-align: center;
}
div.about-text {
  display: inline-block;
  max-width: 50%;
  margin: 10px;
  vertical-align: top;
  padding: 10px;
  float: left;
}
div.about-text ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
h1.page-header {
  font-family: 'Roboto Slab', sans-serif;
  margin: 15px;
}
span.emphasis {
  font-weight: bold;
}
@media (max-width: 500px)
{
  div.me {
    width: 100px;
    height: 100px;
  }
  div.me-holder {
    width: unset;
    display: block;
    margin: auto;
  }
  div.about-text {
    max-width: 100%;
  }
  div.about-text ul {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
}