.contact.page {
  margin: 0;
  text-align: center;
}
.contact-info-section {
  display: inline-block;
}
.contact-info {
  padding: 5px 30px;
  margin-bottom: 40px;
  display: inline-block;
  text-align: left;
}
.contact-entry {
  margin: 10px 0;
}