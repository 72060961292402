.company {
  display: inline-block;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}
.company:hover {
  border-bottom: 1px solid white;
}
.position-header {
  margin-bottom: .5rem;
}
.position-holder {
  margin-bottom: 10px;
  padding-left: 10px;
}
div.job {
  padding: 25px;
  margin-bottom: 20px;
}
span.date {
  float: right;
}
span.job-title {
  font-weight: bold;
}
span.date, span.job-title {
  font-family: 'Roboto Slab', sans-serif;
}
.job-info {
  margin-bottom: 10px;
}
.job-description {
  margin: 0;
}
@media (min-width: 501px)
{
  .position-holder:first-child span.date {
    display: none;
  }
}
@media (max-width: 500px)
{
  span.date {
    float: initial;
    display: block;
    font-size: 11pt;
  }
  span.date.top-date {
    display: none;
  }
  span.job-title {
    display: block;
  }
}