.project
{
  cursor: pointer;
  color: white;
  width: calc(100% - 30px);
  display: block;
  padding: 30px;
  margin: 20px 15px;
  vertical-align: middle;
}
.project-title
{
  display: inline-block;
}
.project:hover
{
  color: white;
  text-decoration: none;
}
.project:hover .project-title {
  text-decoration: underline;
}
.project-header {
  width: 100%;
}
.project-language {
  font-size: 9pt;
  padding: 3px 6px;
  border-radius: 5px;
  margin: 0 5px 5px;
  display: inline-block;
}
.external-link-icon-holder {
  display: inline-block;
  float: right;
}
.project-link {
  display: inline-block;
  float: right;
}
.fa.fa-external-link {
  color: white;
  float: right;
  font-size: 20px;
  margin-left: 20px;
  transform: translateY(2px);
}
.project-link-icon {
  float: right;
  display: inline-block;
  width: 22px;
  height: 22px;
  vertical-align: top;
}
.project-link-icon:hover {
  animation: grow 0.2s ease-in forwards;
}
.project-link-icon.website {
  margin-left: 25px;
}
.hidden {
  display: none !important;
}
.project-image {
  width: 30%;
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0;
}
.project-summary {
  vertical-align: middle;
  display: inline-block;
  width: 60%;
  margin-left: 5%;
  overflow: hidden;
}
@media (max-width: 900px)
{
  .project {
    max-width: 500px;
    display: inline-block;
    padding: 20px;
  }
  .project-image {
    width: 100%;
  }
  .project-summary {
    display: block;
    width: 100%;
    margin: 0;
    font-size: 11pt;
  }
}
@media (max-width: 500px)
{
  .project-link-icon {
    width: 18px;
    height: 18px;
  }
  .fa.fa-external-link {
    margin-left: 10px;
  }
}